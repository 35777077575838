body {
  background-color:#282a36;
}

.App {
  text-align: center;
  color: #f8f8f2;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: larger;
}

.Title {
  /* background-color: #ff79c6; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  text-shadow: 2px 2px 4px #000000;
  }